.contentList {
	min-width: 100% !important;
	padding: 0 4rem;
}
.hintLb {
	color: var(--ion-text-color);
}
.tabContainer {
	--background: var(--ion-color-primary-contrast);
	margin: 1rem 0 1.5rem 0;
	border-radius: 100px;
	border: 2px solid #dddddd;
}
.tabContainer ion-segment-button {
	--color: var(--ion-text-color);
	--color-checked: var(--ion-color-primary);
	--color-hover: var(--ion-text-color);
	--background-hover: var(--ion-main-background-color);
	--background-hover-opacity: 1;
	--indicator-box-shadow: none;
	--border-color: #ddd;
	--padding-bottom: 0.45rem;
	--padding-top: 0.45rem;
	margin: 0;
}
.tabContainer ion-segment-button::before {
	margin-top: 0;
	margin-bottom: 0;
	opacity: 1;
}
.tabContainerAlone {
	display: none;
}
.longerTab {
	min-width: 270px;
}
.tabContent {
	width: 100%;
	height: 519px;
	border-radius: 10px;
	border: solid 1px #dddddd;
	padding: 1rem;
	overflow-y: auto;
}
.tabContent ion-item {
	margin-top: 1rem;
	--background-hover: none;
}
.descriptionContainer {
	width: 100%;
	white-space: pre-line !important;
}
.chkContainer {
	margin-top: 0 !important;
	--padding-start: 0;
}
.chkContainer ion-label {
	font-size: 28px !important;
	padding-top: 0.15rem;
	padding-left: 0.5rem;
	font-family: Industry-Demi !important;
}
.btnContainer {
	display: flex;
}
.btnContainer ion-button {
	max-width: 300px;
	font-size: 15px;
}
.btnContainer ion-button.languageBtn {
	width: 185px;
	margin-left: 3.5rem;
}
.btnContainer ion-button:last-child {
	width: 250px;
	margin-right: 4.5rem;
}
.acceptAllContainer {
	--padding-start: 0;
	--background-hover: none;
	margin-left: 3.5rem;
	margin-bottom: 0rem;
}
.acceptAllContainer ion-label {
	padding-top: 0.15rem;
	padding-left: 0.5rem;
}
.agreementFrame {
	border: none;
	width: 100%;
	padding: 0px 20px;
}
.wrap {
	white-space: normal;
}
