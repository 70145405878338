.editForm {
    width: 100%;
}
.formGrid {
    width: 100%;
    padding: 0;
}
.formGrid ion-list {
    width: 100%;
    margin-bottom: 1.2rem;
}
.formGrid ion-item {
    opacity: 1;
    --border-color: var(--ion-main-background-color);
}
.formGrid ion-list-header {
    margin-top: 1.063rem;
    --background: var(--ion-main-background-color);
    font-family: 'Industry';
    text-transform: uppercase;
    border-radius: 5px;
}
.formGrid ion-input.editable {
    border-radius: 4px;
    border: 1px solid #ccc;
    max-width: 300px;
}
.formGrid ion-input.firstName {
    margin-right: 0.5rem;
}
.formGrid ion-label.userName {
    margin-left: 0.5rem;
}
.formLb {
    font-weight: bold;
    margin-right: 4rem;
    min-width: 170px!important;
}
.formLbItem {
    margin-right: 8px;
    background-color: #eeeeee;
    border-radius: 5px;
    height: 30px;
    padding: 7px 8px;
    flex: unset !important;
    width: fit-content !important;
}
.inputLb {
    margin-left: 0.5rem;
}
.subTitleLb {
    font-family: 'Industry';
    text-transform: capitalize;
    --color: var(--ion-color-gray)!important;
}
.chkLb {
    margin-left: 0.5rem;
}
.ddlLb {
    flex: 1!important;
}
.selectLb {
    margin-left: 0!important;
    font-weight: bold;
    margin-right: 4rem;
    min-width: 170px!important;
}
.groupSelect {
    max-width: 300px;
    flex: 1;
}
.timeZoneSelect {
    width: 100%;
    max-width: 300px;
}
.battTextContainer {
	display: inline-flex;
}
.batteryText {
    padding-left: 0.5rem;
    padding-right: 0.25rem;
}
.batteryText, 
.chargingText {
	font-size: 15px;
	text-align: center;
	display: flex;
	align-items: center;
}
.batteryTextGrey {
	font-size: 15px;
	text-align: center;
	display: flex;
	align-items: center;
	margin-left: 6px;
	color: var(--ion-color-gray-shade)
}
.batteryRoster {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	position: relative;
}
.batteryDetailsWrapper {
    display: none;
}
.batteryDetailsWrapperShow {
	display: block;
    position: absolute !important;
    top: 155px;
    left: 235px;
    z-index: 2;
}
.batteryRosterOff:hover .batteryDetailsWrapper {
	display: none;
}
.favoriteWrapper {
	width: 20px;
	height: 20px;
	cursor: pointer;
}
.favoriteWrapper img {
	width: 100%;
	height: 100%;
}
.availableIcon {
    color: var(--ion-color-primary);
    vertical-align: middle;
}
.hidden {
    display: none !important;
}