.goBeCardIconContainer {
	display: inline-block;
	margin: 20px 25px 0 0;
	width: 235px;
	height: 323px;
	display: inline-block;
	.goBeCardSquaresContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		.verticalCardSquare {
			width: 174px;
			height: 243px;
			background-color: #ffffff;
			display: flex;
			flex-direction: column;
			align-items: center;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.16);
			.blackCardSquare {
				width: 140px;
				height: 213px;
				object-fit: contain;
				background-color: #1e1f22;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				cursor: pointer;
				position: relative;
				.goBeLastSeen {
					color: white;
					font-size: 13px;
					text-align: end;
					margin-right: 8px;
					.lastSeenLogoWrapper {
						width: 20px !important;
						margin-right: 4px;
						display: inline-block;
						position: relative;
						top: 5px;
					}
				}
				.goBeOfflineContainer {
					display: none;
				}
				.goBePlayContainer {
					text-align: center;
					display: flex;
					justify-content: center;
					.goBePlayWrapper {
						width: 100px;
						height: 100px;
						transition: 0.2s;
					}
					.goBePlayWrapper:hover ~ .greenPlayTriangle {
						border-left: 0;
						border-top: 0;
						border-bottom: 0;
					}
					.goBePlayWrapper:hover .greenPlayWrapper {
						width: 40px;
						height: 40px;
						top: 75px;
						left: 50px;
						transition: 0.2s;
					}
					.greenPlayWrapper {
						position: absolute;
						width: 60px;
						height: 60px;
						top: 68px;
						left: 20px;
						z-index: 100;
						transition: 0.2s;
						img {
							width: 100%;
							height: 100%;
						}
					}
					.greenPlayTriangle {
						width: 0;
						height: 0;
						border-left: 40px solid #1e1f22;
						border-top: 40px solid transparent;
						border-bottom: 40px solid transparent;
						position: absolute;
						left: 30px;
						top: 60px;
					}
				}
				.goBeStatusContainer {
					display: none;
				}
				.goBeLocation {
					color: white;
					font-size: 11px;
					text-align: center;
					padding : 8px 0 8px 0;
					font-family: roboto;
				}
				.goBeLocationNull {
					height: 27px;
				}
			}
			.statusSquare {
				cursor: default !important;
				.goBeStatusContainer {
					display: flex;
					text-align: center;
					justify-content: center;
					flex-direction: column;
					align-items: center;
					position: relative;
					.goBeStatusWrapper {
						width: 54px;
						height: 54px;
						img {
							width: 100%;
							height: 100%;
						}
					}
					.goBeStatusText {
						font-family: Industry;
						font-size: 15px;
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 0.87;
						letter-spacing: normal;
						text-align: center;
						color: #a5a5a6;
						margin-top: 5px;
					}
					.goBeStatusIconWrapper {
						width: 24px;
						position: absolute;
						top: 15px;
					}
				}
				.goBePlayContainer {
					display: none;
				}
			}
			.greyCardSquare {
				background-color: #dddddd;
				cursor: default;
				.goBeLocation {
					color: #78787a !important;
				}
				.goBeLastSeen {
					color: #78787a !important;
					font-family: roboto;
				}
				.goBeOfflineContainer {
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					color: #78787a !important;
					.offlineWrapper {
						width: 70px;
						font-size: 18px;
					}
				}
				.goBePlayContainer {
					display: none;
				}
				.goBeStatusContainer {
					display: none;
				}
			}
			.battFavoriteContainer {
				width: 140px;
				height: 30px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.battTextContainer {
					display: flex;
					.batteryText {
						font-size: 13px;
						text-align: center;
						display: flex;
						align-items: center;
						margin-left: 6px;
					}
					.batteryTextGrey {
						color: #78787a;
					}
					.batteryRoaster {
						display: flex;
						justify-content: center;
						align-items: center;
						position: relative;
						.batteryDetailsWrapper {
							display: none;
						}
					}
					.batteryRoaster:hover {
						.batteryDetailsWrapper {
							display: block;
							position: absolute;
							bottom: 40px;
							right: -140px;
						}
					}
					.batteryRoasterOff:hover {
						.batteryDetailsWrapper {
							display: none;
						}
					}
				}
				.favoriteWrapper {
					width: 20px;
					height: 20px;
					cursor: pointer;
					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
		.verticalCardSquareOffline {
			box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0) !important;
		}
		.horizontalCardSquare {
			width: 234px;
			height: 80px;
			border-radius: 10px;
			background-color: #ffffff;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 0 16px;
			box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.16);
			.goBeNameContainer {
				font-size: 15px;
				font-family: industry, sans-serif;
				font-weight: 600;
				font-style: normal;
			}
			.goBeGroupNameContainer {
				font-size: 13px;
				font-family: Roboto;
			}
			.goBeAvailable {
				color: red;
				font-size: 18px;
				text-align: center;
				font-family: roboto;
				margin-top: 8px;
			}
		}
		.horizontalCardSquareOffline {
			color: #78787a !important;
			box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0) !important;
		}
	}
}
