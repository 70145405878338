.leftSide {
	display: flex;
	flex-direction: column;
	width: 13.25rem;
	background-color: var(--ion-color-primary-contrast);
	border-radius: 0.313rem;
	padding: 0.825rem 0.5rem 0;
	margin-right: 1rem;
}
.rightSide {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.addRobotBtnContainer {
	display: flex;
	align-items: center;
	padding: 0.3em 0 1em;
	border-bottom: 1px solid rgba(0, 0, 0, 0.04);
	height: (82px - 13.2px);
}
.transferRobotBtnContainer {
	display: inline-flex;
	align-items: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.04);
	margin-left: 12px;
	padding: 0.712em 0;
	height: 82px;
	width: calc(100% - 12px);
}
.addBtn {
	--padding-end: 0.5rem;
	--padding-start: 0.5rem;
	--color: var(--ion-text-color);
	--background: var(--ion-color-primary-contrast);
}
.addRobotIcon {
	margin-bottom: 8px;
	margin-right: 0.5rem;
	width: 22px;
	height: 22px;
	margin-left: 8px;
}
.addLb {
	flex-grow: 1;
	font-weight: bold;
	font-family: 'Industry';
}

.nameContainer,
.groupContainer {
	display: flex;
	align-items: center;
}
.robotAvatar {
	display: flex;
	justify-content: center;
	height: 100%;
	width: 60px;
	margin-right: 5px;
	background: black;
	border-radius: 0;
}
.robotAvatarOffline {
	display: flex;
	background-color: #dddddd !important;
	justify-content: center;
	height: 100%;
	width: 62px;
	margin-right: 5px;
	border-radius: 0;
}
.profilePic {
	width: 38px;
	height: 38px;
}
.noPic {
	color: var(--ion-text-color);
	background-color: var(--ion-text-color);
}
/* more popup */
.itemContent {
	text-align: left;
	padding: 0.375rem 0.375rem 0;
}
.moreList tr:last-child .itemContent {
	padding-bottom: 0.375rem;
}
.itemLb {
	display: flex;
	width: 100%;
	padding: 0.375rem 0.5rem !important;
}
.itemLb:hover {
	background-color: var(--ion-main-background-color);
	border-radius: 5px;
	cursor: pointer;
}

.battTextContainer {
	display: flex;
}
.batteryText {
	font-size: 13px;
	text-align: center;
	display: flex;
	align-items: center;
	margin-left: 6px;
}
.batteryTextGrey {
	font-size: 13px;
	text-align: center;
	display: flex;
	align-items: center;
	margin-left: 6px;
	color: var(--ion-color-gray-shade);
}
.batteryRoster {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}
.batteryDetailsWrapper {
	display: none;
}
.batteryRoster:hover .batteryDetailsWrapper {
	display: block;
	position: absolute;
	bottom: 30px;
	right: -140px;
}
.batteryRosterOff:hover .batteryDetailsWrapper {
	display: none;
}
.favoriteWrapper {
	width: 20px;
	height: 20px;
	cursor: pointer;
}
.favoriteWrapper img {
	width: 100%;
	height: 100%;
}
.offlinePic {
	background-color: #dddddd;
	color: #a5a5a6;
}
.noData {
	color: var(--ion-color-gray-shade);
	font-weight: bold;
	width: 100%;
	text-align: center;
	margin-top: 1rem;
}
.noDataHint {
	font-weight: normal;
	margin-bottom: 1rem;
}

.transferRobotsContainer {
	padding: 5px 5px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.disabledSelectRobotBtn {
	--color: white;
	--border-color: var(--ion-text-color);
	--background: var(--ion-color-primary);
	--border-width: 1px;
	opacity: 0.25;
	height: 50px;
	width: 100%;
	--border-radius: 5px;
	text-transform: initial;
}

.selectRobotBtn {
	--color: white;
	--border-color: var(--ion-color-primary) !important;
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary);
	--background-hover-opacity: 1;
	height: 50px;
	width: 100%;
	--border-radius: 5px;
	text-transform: initial;
}

.headerTitle {
	font-family: 'Industry';
	font-size: 28px !important;
	text-transform: capitalize;
}

.modalHeader {
	flex-direction: column;
}

.selectedRobotsContainer {
	display: flex;
	align-items: center;
	width: 100%;
	margin-top: 30px;
	max-height: 300px;
	overflow-y: scroll;
}

.selectedRobotsContainer::-webkit-scrollbar {
	width: 8px;
}

.selectedRobotsContainer::-webkit-scrollbar-track {
	border-radius: 10px;
}

.selectedRobotsContainer::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px 6px var(--ion-color-gray-tint);
}

.robotContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	height: 30px;
	margin: 2px 5px;
	border-radius: 5px;
	background-color: var(--ion-main-background-color);
}

.deleteRobotBtn {
	--padding-end: 0.5rem;
	--padding-start: 0.5rem;
	--color: var(--ion-color-gray);
	--box-shadow: none;
	height: 50px;
	width: 50px;
	margin: 0 -10px;
}
.textGrey,
.userNameLb {
	text-overflow: ellipsis;
	overflow: hidden;
	width: 140px;
	white-space: nowrap;
}
.textGrey {
	color: var(--ion-color-gray-shade);
}

.textGrey:hover,
.userNameLb:hover {
	text-overflow: none;
	overflow: auto;
	min-width: 140px;
	width: auto;
	white-space: nowrap;
}
