.icon {
	margin-right: 0.5rem;
}

/* Organization details */
.formGrid {
	height: 700px;
	width: 100%;
	padding: 0;
	margin-bottom: 3.5rem;
	
}

.formGrid ion-list {
	width: 100%;
	padding: 0;
}

.formGrid .firstCol {
	margin-right: 5rem;
	margin-top:15px;
}



.formGrid ion-item {
	opacity: 1;
	--border-color: var(--ion-main-background-color);
}

.formGrid ion-list-header {
	margin-top: 1.063rem;
	--background: var(--ion-main-background-color);
	font-family: 'Industry';
	text-transform: uppercase;
	border-radius: 5px;
}

.detailHeader {
	font-family: 'Industry-Demi';
}

.selectContainer {
	margin: 0 0.5em 0.5em 0.5em;
}


.urlExpand {
	width: 100%;
	display: inline-flex;
	align-items: center;
}

.expandInput {
	width: 250px !important;
	font-family: Roboto;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: var(--ion-color-gray-shade);
}

.iconContainer {
	width: max-content;
}

.icon {
	width: 13.8px;
	height: 14.8px;
	margin-right: 8px;
}

.iconExpanded {
	color: var(--ion-color-gray-shade);
	width: 26px;
	height: 26px;
	margin: 14px 7px 10px 16px;
}

.hidden {
	display: none;
}

.createdOnLb {
	--color: var(--ion-color-gray) !important;
	margin-top: 2rem;
}

.profileListHeader {
	--background: transparent !important;
}

.profileHeader {
	font-family: 'Industry' !important;
	font-size: 28px !important;
	display: flex;
	justify-content: center;
	width: 100%;
}

.sameAsLb {
	color: var(--ion-color-gray-shade) !important;
	text-transform: none !important;
	padding-left: 0.5rem;
	font-family: 'Roboto' !important;
	font-size: 13px !important;
}

.sameAsChk {
	margin-left: 0.5rem;
}

.billingList {
	margin-right: 2rem;
}

.zipCityContainer {
	display: flex;
}

.zipCityContainer div {
	width: 100%;
}

.formItemContainer {
	margin: 0 0.5em 0.5em;
}

.formItem {
	--highlight-color-focused: var(--ion-color-gray-shade);
	--highlight-color-invalid: var(--form-field-error-color);
	--highlight-height: 1px;
	--padding-start: 0;
	--padding-end: 0;
}

.formItem:hover {
	--border-color: var(--ion-color-gray-shade);
	--border-width: 0 0 1px 0;
}

.formItem:active {
	--highlight-background: var(--form-field-active-color);
}

.formItem ion-label {
	margin-bottom: 10px;
}

.formItem ion-label.lbColor {
	color: var(--ion-text-color) !important;
}

.formItem .colorFocused,
.formItem ion-label.colorFocused {
	color: var(--ion-color-primary) !important;
}

.formItem .hasFocus,
.formItem ion-label.hasFocus {
	color: var(--ion-color-primary) !important;
}

.formItem ion-text {
	color: var(--ion-color-primary);
	padding-left: 3px;
}

.errorItem {
	--border-color: var(--form-field-error-color) !important;
	--highlight-background: var(--form-field-error-color) !important;
}

.errorMsg {
	color: var(--form-field-error-color);
	font-size: 0.8em;
	width: 100%;
	display: block;
	padding-top: 5px;
}

.inputIcon {
	display: inline-flex !important;
	width: 100%;
}

.inputIconSize {
	height: 20px;
	width: 20px;
	margin: auto 0;
}

.robotTimeZoneItem{
	margin-top:5px
}

.robotTimeZoneSelect{
	margin-top:5px;
	width:100%
}


.rectangleLocalVoice {
	transition: 0.5s;
	width: 0px;
	overflow: hidden;
}

.rectangleLocalVoice:hover {
	width: 160px;
	height: 50px;
	opacity: 1 !important;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: default;
	transition: 0.5s;
}

.sliderContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	height: 30px;
	width: 100%;
	padding: 10px;
	margin-left:-15px;
	
}

.sliderDiv{
	background-color: #eeeeee;
	max-width:296px ;
	height:50px;
	border-radius: 50px;
	margin-top:25px;
	margin-left:-20px;
}

.sliderDiv2{
	background-color: #eeeeee;
	max-width:296px ;
	height:50px;
	border-radius: 50px;
	margin-top:21px;
	margin-left:-20px;
}

.volumeStatusText{
	color: var(--ion-color-primary) !important;
font-size:18px !important;

}

.volumeStatusTextWhenIs0{color: red !important;
	font-size:18px !important;}

.ionIcon{
	font-size:20px;
	margin-top:-6px;
	color:#a5a5a6;
}

.ionIconOff{
	font-size:20px;
	margin-top:-6px;
	color:red;
}
.leftCol{
	margin-top:10px;
	text-align:right
}

.rightCol{
	margin-top:10px;
}

.ionIconVolume{
	color: var(--ion-color-primary) !important;
	margin-top:35px;
}

.firstSliderLabel{
	max-width:60px;
}

.secondSliderLabel{
	margin-top:35px !important;
	color:#78787a;
	max-width:60px;
}

.secondSliderLabel:hover{
	cursor:pointer;
}


.borderCameraScreen{
	height:300px;
	width:280px;
	background-color: #1e1f22;
	padding:20px;
	margin-top:50px;
	margin-left:65px;
}

.borderCameraScreen .CameraScreen{
	width:240px;
	height: 260px;
	background-color: #78787a;
	
}

.sliderContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	height: 30px;
	width: 100%;
	padding: 10px;
}
.sliderContainer .inputR {
	/* -webkit-appearance: none; */
	appearance: none;
	width: 150px;
	height: 6px;
	border-radius: 2px;
	opacity: 1;
	outline: none;
	cursor: pointer;
}
.sliderContainer .inputR::-webkit-slider-thumb {
	appearance: none;
	/* -webkit-appearance: none; */
	background: #56ae4d;
	height: 25px;
	width: 25px;
	border-radius: 50%;
}
.cursorointer {
	cursor: pointer;
}



.sliderCol{
	margin-left:-20px;
	margin-top:7px;
}



.leftColWithSelect{
	min-width:400px;
}

.rightColWithSelect{
	min-width:400px;
}

.rowSliders{
	min-width:300px;
}

.label{
	min-width:50px
}

 .videoLandscape {
	width:294px;
	height:391px;
	overflow: hidden;	
  }

  .videoRow{
	  margin-top:30px;
	  margin-left:-15px;
  }

  .videoStream{
	height:100%;
	transform:translateX(-20%)
  }

  /* .videoLandscape::after{
	  display: block;
	  content:''; scaleX(-1)
	  padding-top: 130%;
	position: absolute;
	top:0;
  } */

  .whiteLogoWrapper {
	position: absolute;
	width: 50px;
	top: 50px;
	right: 10px;
}

.audioRecognizeContainer {
	width: 15px;
	height: 15px;
	
	top: 50px;
	left: 10px;
	display: flex;
	margin-top:40px;
	margin-left:25px;
	justify-content: space-between;
	align-items: center;
	
}

.audioRecognizeContainer .audioStrength {
	background-color: #56ae4d;
	width: 4px;
	max-height: 10px;
	border-radius: 100%;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
}

.audioRecognizeContainer .audioStrengthMax{
	background-color: #56ae4d;
	width: 4px;
	max-height: 20px;
	border-radius: 100%;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
}