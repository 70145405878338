.leftSide {
    display: flex;
    flex-direction: column;
    width: 13.25rem;
    background-color: var(--ion-color-primary-contrast);
    border-radius: 0.313rem;
    padding: 0.825rem 0.5rem 0;
    margin-right: 1rem;
}
.rightSide {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.nameContainer {
    display: flex;
    align-items: center;
}
.groupAvatar {
  display: flex;
  justify-content: center;
  background-color: var(--ion-color-gray-contrast);
  border-radius: 0;
  max-width: 3.875rem;
  height: 100%;
	margin-right: 0.5rem;
}
.groupPic {
  width: 100%;
  height: 100%;
  border-radius: 0;	
}
/* more popup */
.itemContent {
  text-align: left;
  padding: 0.375rem 0.375rem 0;
}
.moreList tr:last-child .itemContent {
  padding-bottom: 0.375rem;
}
.itemLb {
  color: var(--ion-more-lb-color);
  display: flex;
  width: 100%;
  padding: 0.375rem 0.5rem!important;
  text-transform: capitalize;
}
.itemLb:hover {
  background-color: var(--ion-main-background-color);
  border-radius: 5px;
  cursor: pointer;
}
/* create group button */
.createBtnContainer {
  display: flex;
  align-items: center;
  padding-bottom: 1.375rem;;
  border-bottom: 1px solid rgba(0,0,0,0.04);
}
.createBtn {
  --padding-end: 0.5rem;
  --padding-start: 0.5rem;
  --color: var(--ion-text-color);
  --background: var(--ion-color-primary-contrast);
}
.createIcon {
  height: 20px;
  width: 20px;
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;
}
.createLb {
  flex-grow: 1;
  font-weight: bold;
  font-family: 'Industry';
}
.robotsCount {
  display: flex;
  justify-content: center;
}
/************************* RobotGroup Edit ******************************/
.editLeftSide {
  display: flex;
  flex-direction: column;
  width: 22.5rem;
  margin-right: 1.5rem;
}
.recentContainer {
  display: flex;
  flex-grow: 1;
  width: 100%;
  background-color: var(--ion-color-primary-contrast);
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
  padding: 1.063rem 0.5rem;
}
.recentLb {
  font-family: 'Industry';
  font-weight: bold;
  margin-left: 0.5rem;
}
.recentContainer ion-list {
  width: 100%;
}
.recentContainer .profilePic {
  width: 2.5rem;
  height: 2.5rem;    
}
.recentContainer ion-avatar {
  margin-right: 0.5rem;
}
.recentContainer .icon {
  color: var(--ion-text-color);
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.recentContainer .info, .recentContainer .timeIcon {
  color: var(--ion-color-gray-shade);
}
.createdUserGroup {
  display: flex;
  margin: 1rem 0.3rem 1rem 0;
  color: var(--ion-color-gray-shade);
}
.createdUserGroup ion-icon {
  margin-right: 0.5rem;
}
.createdUserGroup span {
  text-transform: capitalize;
  margin-right: 0.3rem;
  display: contents;
}
.hasRobotsLb {
  text-align: center;
  margin-top: 3rem;
}
/************************* Robots Grid ******************************/
.nameContainer {
	display: flex;
	align-items: center;
}
.avatar {
	/* max-width: 2.5rem; */
	height: 100%;
	margin-right: 0.5rem;
}
.profilePic {
  width: 38px;
	height: 38px;
}
.noPic {
  color: var(--ion-color-primary-contrast);
  background-color: var(--ion-color-gray-contrast);
}
.offlinePic {
  color: var(--ion-color-gray);
  background-color: #dddddd;
  padding: 0 0.75rem;
}
.offlineColor {
  color: var(--ion-color-gray);
}
.groupNameLb {
  display: flex;
}
.groupNameItem {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: block;
}
.groupNameItem li {
  margin-top: 0.25rem;
}
.groupLink:hover {
  text-decoration: underline;
  width: 100%;
}
/* more popup */
.itemContent {
	text-align: left;
	padding: 0.375rem 0.375rem 0;
}
.moreList tr:last-child .itemContent {
	padding-bottom: 0.375rem;
}
.itemLb {
	display: flex;
	width: 100%;
	padding: 0.375rem 0.5rem!important;
}
.itemLb:hover {
	background-color: var(--ion-main-background-color);
	border-radius: 5px;
	cursor: pointer;
}
/* Edit user group modal*/
.addForm {
	height: 100%;
	margin: 2.25rem 2.3rem;
	display: flex;
	flex-direction: column;
}
.modalContent {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
}
.editGrid {
  margin-top: 1.5rem;
  padding: 0 3rem;
}
.header {
  width: 100%;
}
.modalHeader {
	display: flex;
  flex-direction: column;
	text-align: center;
	margin-bottom: 1rem;
	padding: 0;
  margin-top: 2.5rem;
}
.headerContainer {
	display: flex;
	margin: 0 auto;
}
.headerTitle {
	font-family: 'Industry';
	font-size: 28px !important;
	text-transform: capitalize;
}
.headerHint {
	color: var(--ion-color-gray-shade);
	margin: 1rem 0;
}
.editModal {
	--width: 613px;
	--height: 640px;
	min-height: 633px;
	--border-radius: 10px;
}
.editModal .headerHint {
  margin: 0.5rem 0;
}
.editModal .btnRow {
  margin-bottom: 1.8rem;
}
.editModal .modalHeader {
  margin-bottom: 2rem;
}
.deleteFab {
  margin: 10px;
}
.deleteFab ion-fab-button {
  margin: 0;
  bottom: 0;
  --background: var(--ion-color-danger);
  --background-hover: var(--ion-color-danger-hover);
  --background-hover-opacity: 1;
  --color: var(--ion-color-primary-contrast);
  --box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
}
.deleteOverlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  --background: var(--ion-text-color);
  background-color: var(--ion-text-color);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);    
  z-index: 1;
  opacity: 0.9;
}
.deleteOverlay ion-row:first-child {
  flex-grow: 1;
}
.editableContent {
  flex-grow: 1;
}
.editableContent ion-list {
  width: 100%;
}
.addUsersItem {
  margin-bottom: 2rem;
}
.addUsersItem ion-button {
  font-size: 15px;
  text-transform: capitalize;
  margin: 0 auto;
  --color-hover: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-color-primary);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}
.addUsersItem ion-icon {
  margin-right: 0.3rem;
  height: 25px;
  width: 25px;
}
.addedRow {
  display: block;
}
.addedLb {
  margin-top: 0;
  text-align: center;
}
.addedLb ion-icon {
  position: relative;
  top: 5px;
  margin-right: 0.3rem;
}

.btnRow {
	display: flex;
	align-items: flex-end;
	margin-top: 2rem;
	margin-bottom: 0.5rem;
}
.btnRow ion-button {
	width: 185px;
	height: 50px;
	font-size: 15px;
}
.btnRow ion-col:first-child {
	display: flex;
	justify-content: flex-end;
}
.submitCol ion-button {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}
.cancelBtn {
	--color: var(--ion-text-color);
	--color-hover: var(--ion-color-primary-contrast);
	--border-color: var(--ion-text-color);
	--border-width: 1px;
	--background: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-text-color);
	--background-hover-opacity: 1;
}