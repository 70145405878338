.container {
    display: block;
    position: relative;
    padding-left: 2em;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
  
/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
  
/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 4px;
    border: solid 1px #8e8c89;
    background-color: #ffffff;
}
  
/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
    border: solid 2px #666666;
}
  
/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
    background-color: var(--ion-color-primary);
    border: none;
}
  
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
  
/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}
  
/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}