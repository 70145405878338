.leftSide {
    display: flex;
    flex-direction: column;
    width: 13.25rem;
    background-color: var(--ion-color-primary-contrast);
    border-radius: 0.313rem;
    padding: 0.825rem 0.5rem 0;
    margin-right: 1rem;
}
.rightSide {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.createBtnContainer, .inviteBtnContainer {
  display: flex;
  align-items: center;
  padding-bottom: 1.375rem;;
  border-bottom: 1px solid rgba(0,0,0,0.04);
}
.inviteBtnContainer {
  padding-top: 1.375rem;
}
.createBtn, .inviteBtn {
  --padding-end: 0.5rem;
  --padding-start: 0.5rem;
  --color: var(--ion-text-color);
  --background: var(--ion-color-primary-contrast);
}
.inviteIcon {
  margin-right: 0.5rem;
}
.inviteLb {
  flex-grow: 1;
  font-weight: bold;
  font-family: 'Industry';
}

.nameContainer {
  display: flex;
  align-items: center;
}
.userAvatar {
  max-width: 2.5rem;
  height: 100%;
  margin-right: 0.5rem;
}
.profilePic {
	width: 38px;
	height: 38px;
}
.noPic {
    color: var(--ion-text-color);
}
/* Create modal */
.addModal {
	--width: 613px;
	--height: 816px;
	min-height: 733px;
	--border-radius: 10px;
}
.addForm {
	height: 100%;
	margin: 2.25rem 2.3rem;
	display: flex;
	flex-direction: column;
}
.modalContent {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
}
.userGroupNameContainer div:first-child {
  width: 100%;
}
.modalContent .usersContent {
	flex-grow: 1;
}
.usersContent ion-list {
  width: 100%;
}
.header {
  width: 100%;
}
.modalHeader {
	display: flex;
  flex-direction: column;
	text-align: center;
	margin-bottom: 1rem;
	padding: 0;
  margin-top: 2.5rem;
}
.headerContainer {
	display: flex;
	margin: 0 auto;
}
.headerIcon {
	margin-right: 0.5rem;
}
.headerTitle {
	font-family: 'Industry';
	font-size: 28px !important;
	text-transform: capitalize;
}
.headerHint {
	color: var(--ion-color-gray-shade);
	margin: 1rem 0;
}
.groupName {
  font-weight: 600;
}
.usersRow {
  margin-top: 1.2rem;
  margin-left: 0.5rem;
}
.usersRow ion-col {
  flex: 1;
  overflow-y: auto;
}
.usersRow ion-col:first-child {
  padding-right: 2.4rem;
}
.usersList, .noSelection {
  overflow-y: auto;
  max-height: 300px;
  padding: 5px!important;
}
.noSelection {
  margin-top: 0.9rem;    
}
.noSelection ion-label {
  color: var(--ion-color-gray);
}
.noUsers {
  display: flex;
  margin-top: 1.3rem;
  color: var(--ion-color-gray);
}
.usersSelected {
  display: flex;
  padding-top: 1.5rem;
  padding-bottom: 1.4rem;
  color: var(--ion-color-gray);
}
.btnRow {
	display: flex;
	align-items: flex-end;
	margin-top: 2rem;
	margin-bottom: 0.5rem;
}
.btnRow ion-button {
	width: 185px;
	height: 50px;
	font-size: 15px;
}
.btnRow ion-col:first-child {
	display: flex;
	justify-content: flex-end;
}
.submitCol ion-button {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}
.cancelBtn {
	--color: var(--ion-text-color);
	--color-hover: var(--ion-color-primary-contrast);
	--border-color: var(--ion-text-color);
	--border-width: 1px;
	--background: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-text-color);
	--background-hover-opacity: 1;
}
.colTitle {
  text-transform: capitalize;
  font-family: 'Industry';
  font-size: 18px!important;
  margin-bottom: 0.9rem;
}
.searchBar {
  --placeholder-color: var(--ion-text-color);
  --placeholder-opacity: 1;
  font-size: 13px;
  text-align: left;
  width: 100%;
  margin: 10px 0 8px!important;
}
.searchBar ion-icon {
  color: var(--ion-text-color)!important;
}
.searchBar input {
  padding-left: 0!important;
  border-bottom: 1px solid var(--ion-color-gray-tint)!important;
  font-size: 15px!important;
}
.userItem {
    margin-bottom: 0.5rem;
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
    background-color: var(--ion-color-primary-contrast);
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.375rem;
    --inner-padding-end: 0;
}
.userPicContainer {
    width: 62px;
    height: 38px;
    margin-left: 0.375rem;;
}
.userPic {
    height: 100%;
    width: 42px;
    font-size: 1.8em;
    margin-left: 0.3em;
}
.userName {
    margin-left: 0.5rem;
    font-weight: 500;
    flex-grow: 1;
}
.selectBtn {
    --background: var(--ion-color-primary-contrast);
    --color: var(--ion-text-color);
    --box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
	border-radius: 50%;
    /* margin: 0 0.5rem 0 0; */
    width: 23px;
    height: 23px;
}
.selectBtn ion-icon.fabIcon {
    width: 1rem;
    height: 1rem;
}
.hidden {
  display: none;
}
.membersCount {
  display: flex;
  justify-content: center;
}
/* more popup */
.itemContent {
  text-align: left;
  padding: 0.375rem 0.375rem 0;
}
.moreList tr:last-child .itemContent {
  padding-bottom: 0.375rem;
}
.itemLb {
  color: var(--ion-more-lb-color);
  display: flex;
  width: 100%;
  padding: 0.375rem 0.5rem!important;
  text-transform: capitalize;
}
.itemLb:hover {
  background-color: var(--ion-main-background-color);
  border-radius: 5px;
  cursor: pointer;
}
.noData {
  color: var(--ion-color-gray-shade);
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin-top: 1rem;
}
.noDataHint {
  font-weight: normal;
  margin-bottom: 1rem;
}
/* Edit user group modal*/
.editModal {
	--width: 613px;
	--height: 640px;
	min-height: 633px;
	--border-radius: 10px;
}
.editModal .headerHint {
  margin: 0.5rem 0;
}
.editModal .btnRow {
  margin-bottom: 1.8rem;
}
.editModal .modalHeader {
  margin-bottom: 2rem;
}
.deleteFab {
  margin: 10px;
}
.deleteFab ion-fab-button {
  margin: 0;
  bottom: 0;
  --background: var(--ion-color-danger);
  --background-hover: var(--ion-color-danger-hover);
  --background-hover-opacity: 1;
  --color: var(--ion-color-primary-contrast);
  --box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
}
.deleteOverlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  --background: var(--ion-text-color);
  background-color: var(--ion-text-color);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);    
  z-index: 1;
  opacity: 0.9;
}
.deleteOverlay ion-row:first-child {
  flex-grow: 1;
}
.editGrid {
  margin-top: 1.5rem;
  padding: 0 3rem;
}
.fullImage {
	min-width: 200px;
	min-height: 200px;
}
.groupAvatar {
	margin: auto !important;
	height: 200px;
	width: 200px;
}
.editableContent {
  flex-grow: 1;
}
.editableContent ion-list {
  width: 100%;
}
.addUsersItem {
  margin-bottom: 2rem;
}
.addUsersItem ion-button {
  font-size: 15px;
  text-transform: capitalize;
  margin: 0 auto;
  --color-hover: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-color-primary);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}
.addUsersItem ion-icon {
  margin-right: 0.3rem;
  height: 25px;
  width: 25px;
}
.addedRow {
  display: block;
}
.addedLb {
  margin-top: 0;
  text-align: center;
}
.addedLb ion-icon {
  position: relative;
  top: 5px;
  margin-right: 0.3rem;
}
.robotGroupSelect {
	margin-top: 0.75rem;
}
.robotGroupSelect div {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
}
.tipContent {
  background-color: black;
}
/************************* UserGroup Edit ******************************/
.editLeftSide {
  display: flex;
  flex-direction: column;
  width: 22.5rem;
  margin-right: 1.5rem;
}
.recentContainer {
  display: flex;
  flex-grow: 1;
  width: 100%;
  background-color: var(--ion-color-primary-contrast);
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
  padding: 1.063rem 0.5rem;
}
.recentLb {
  font-family: 'Industry';
  font-weight: bold;
  margin-left: 0.5rem;
}
.recentContainer ion-list {
  width: 100%;
}
.recentContainer .profilePic {
  width: 2.5rem;
  height: 2.5rem;    
}
.recentContainer ion-avatar {
  margin-right: 0.5rem;
}
.recentContainer .icon {
  color: var(--ion-text-color);
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.recentContainer .info, .recentContainer .timeIcon {
  color: var(--ion-color-gray-shade);
}
.createdUserGroup {
  display: flex;
  margin: 1rem 0.3rem 1rem 0;
  color: var(--ion-color-gray-shade);
}
.createdUserGroup ion-icon {
  margin-right: 0.5rem;
}
.createdUserGroup span {
  text-transform: capitalize;
  margin-right: 0.3rem;
  display: contents;
}