@media (max-width: 576px) {
    .blueoceanLogo {
        max-width: 55px!important;
        bottom: 20px!important;
    }
} 
@media (min-width: 576px) and (max-width: 768px) {
    .blueoceanLogo {
        max-width: 55px!important;
        bottom: 20px!important;
    }
}
.mainContent {
    --background: var(--ion-main-background-color);
    --padding-start: 3.125rem;
    --padding-bottom: 0;
    --padding-end: 3.125rem;
    --padding-top: 2rem;
    --offset-top: 70px !important;
}
.pageContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.pageHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */
    max-height: 40px;
    padding-bottom: 1rem;
}
.pageTitle {
    color: var(--ion-text-color);
    font-size: 28px!important;
    height: 50px;
}
.pageContent {
    flex-grow: 1;
    margin-top: 1em;
}
.dividerContainer {
    width: calc(calc(100% + 70px) - 30%);
    display: flex;
    align-items: center;
    margin: 0 auto;
}
.dividerContainer div:nth-of-type(1) {
    flex-grow: 1;
}
.dividerContainer div:nth-of-type(3) {
    flex-grow: 1;
}
.divider {
    width: 100%;
    height: 2px;
    background-color: var(--ion-background-color-divider);
}
.logoContainer {
    width: 109px;
    height: 67px;
}
.blueoceanLogo {
    width: calc(100% - 30px);
    height: 38px;
    margin: 9px 15px 20px;
}
.padding {
    padding: 25px 0;
}
.hidden {
    display: none;
}