@media (max-width: 576px) {
    .searchBar {
        height: auto!important;
    }
} 
@media (min-width: 576px) and (max-width: 768px) {
    .searchBar {
        height: auto!important;
        width: 1000px;
    }
}
.headerBtn {
    --background-hover: var(--ion-color-primary-contrast)!important;
    --background-hover-opacity: 0.1;
    --background-activated: var(--ion-color-primary-contrast)!important;
    --background-activated-opacity: 1;
    --background-focused: var(--ion-color-primary-contrast)!important;
    --background-focused-opacity: 1;
}
.headerIcon {
    color: var(--ion-header-icon-color);
    /* --color-activated: black; */
}
.searchBar {
    --placeholder-color: var(--ion-color-gray-tint);
    font-size: 13px;
    text-align: left;
    /* height: var(--ion-height-toolbar); */
    width: 100%;
    margin: 10px 8px 8px!important;
}
.searchBar ion-icon {
    color: var(--ion-text-color)!important;
}
.notifHeaderBtn {
    margin-right: 0.5em!important;
}
.notifHeaderBtn .badge {
    position: absolute;
    top: -6px;
    right: -4px;
    padding: 5px 5px;
    border-radius: 50%;
    background-color: var(--ion-color-primary)!important;
    color: white;
    border: 2px solid white;
}
.verticalDivider {
    border-left: 1px solid #ECEBF0;
    width: 1px;
    height: 25px;
    padding-right: 0.5em;
}
.userHeaderBtn ion-icon {
    color:#A5AEBD;
    padding-left: 0.5em;
}
.logo {
    height: 49px;
    width: 95px;
    font-size: 1.8em;
    background-color: white;
    background: var(--robot-logo);
    background-repeat: no-repeat;
    background-position: left;
    margin-left: 0.3em;
}
.userNameList {
    color: var(--ion-text-color);
    /* background-color: var(--ion-color-primary-contrast); */
    /* border-radius: 10px; */
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
}
.userNameList td:first {
    padding-top: 1em;
}
.userNameList td {
    padding: 0 2em;
    text-align: center;
}
.listItem {
    border-bottom: 1px solid #efefef;
    padding-bottom: 1em;
    padding-top: 1em;
}
.statusLeft {
    padding-right: 0!important;
    font-weight: bold;
}
.statusRight {
    padding-left: 0!important;
}
.userNameList tr:last-child div.listItem {
    border-bottom: none;
}
.userNameList tr:first-child :hover {
    cursor: default;
}
.userNameList tr:hover {
    cursor: pointer;
}
.userNameList tr:first-child :hover .listItem {
    color: var(--ion-text-color);
}
.userNameList tr:hover .listItem {
    color: var(--ion-color-menu-item-hover);
}
.searchResultsList {
    width: 100%;
    max-height: 18.75rem;
    position: absolute;
    top: calc(var(--ion-height-toolbar) - 3px);
    z-index: 1;
    overflow-y: auto;
}
.searchResultsList tr:last-child {
    border-bottom: none;
}
.hidden {
    display: none;
}