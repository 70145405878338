.leftSide {
    display: flex;
    flex-direction: column;
    width: 13.25rem;
    background-color: var(--ion-color-primary-contrast);
    border-radius: 0.313rem;
    padding: 0.825rem 0.5rem 0;
    margin-right: 1rem;
}
.rightSide {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: var(--ion-color-primary-contrast);
    position: relative;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
    padding: 1.063rem 2.5rem;
}
.header {
    display: flex;
}
.formBtns {
    display: flex;
}
.formBtns ion-icon {
    color: var(--ion-color-gray);
}
.formBtns ion-icon:hover {
    cursor: pointer;
}
.hidden {
    display: none!important;
}
.newBtnContainer {
    display: flex;
    align-items: center;
    padding-bottom: 1.425rem;;
    padding-left: 0.5rem;
    border-bottom: 1px solid rgba(0,0,0,0.04);
}
.newLb {
    flex-grow: 1;
    font-weight: bold;
    font-family: 'Industry';
    text-transform: capitalize;
}
.newBtn {
    --padding-end: 0.5rem;
    --padding-start: 0.5rem;
    --color: var(--ion-text-color);
    --background: var(--ion-color-primary-contrast);
}
.editBtnContainer {
    flex-grow: 1;
    display: flex;
    align-items: center;
}
.editBtn {
    --padding-top: 1rem;
    --padding-bottom: 1rem;
    --padding-start: 0.5rem;
    --padding-end: 0.5rem;
    --color: var(--ion-color-primary-contrast);
    margin-right: 1rem;
}
.editable {
    --background: #377831;
    opacity: 1;
}
.savedLb {
    color: var(--ion-color-primary);
    font-size: 13px;
    margin-left: 0.5rem;
}
.tabContainer {
    --background: var(--ion-color-primary-contrast);
    margin: 0 3rem;
    border-radius: 100px;
    border:2px solid #dddddd;
    max-width: 470px;
}
.tabContainer ion-segment-button {
    --color: var(--ion-text-color);
    --color-checked: var(--ion-color-primary);
    --color-hover:  var(--ion-text-color);
    --background-hover: var(--ion-main-background-color);
    --background-hover-opacity: 1;
    --indicator-box-shadow: none;
    --border-color: #ddd;
    --padding-bottom: 0.45rem;
    --padding-top: 0.45rem;
    margin: 0;
}
.tabContainer ion-segment-button::before {
    margin-top: 0;
    margin-bottom: 0;
    opacity: 1;
}
.detailsBtn {
    max-width: 90px;
    --background: var(--ion-color-primary-contrast);
    --color: var(--ion-color-primary);
}
.goBeCareBtn {
    max-width: 110px;
}
.segmentContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    height: 50%;
    overflow-y: scroll;
    overflow: hidden;
}
.segmentContent ion-row:first-child {
    flex-grow: 1;
    height: 85%;
}
.goBeHeader {
    background-color: var(--ion-main-background-color);
    font-family: 'Industry';
    text-transform: uppercase;
    padding: 0.5rem 1rem 0.8rem;
    border-radius: 5px;
    margin-top: 0.438rem;
}