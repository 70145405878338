@media (max-width: 576px) {
    .loginGrid .topLogoContainer {
        background-color: var(--ion-color-primary);
        flex-grow: 1;
    }
    .loginFormContainer {
        align-items: flex-start!important;
    }
    .loginFormContainer ion-grid {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0!important;
    }
    .loginGrid .logo {
        align-self: flex-end;
    }
    .loginForm {
        width: 100%;
        max-width: none!important;
        padding: 0 20px 20px!important;
    }
    .loginForm .btnRow {
        padding-top: 1em!important;
        padding-bottom: 2em;
    }
    .loginForm .btnRow ion-col {
        padding-left: 0!important;
        margin-right: 0!important;
    }
    .loginForm .btnRow ion-button {
        max-width: 100%!important;
        margin: none!important;
    }
    .blueoceanLogo {
        max-width: 55px!important;
        bottom: 20px!important;
    }
} 
@media (min-width: 576px) and (max-width: 768px) {
    .loginGrid .topLogoContainer {
        background-color: var(--ion-color-primary);
        flex-grow: 1;
    }
    .loginFormContainer {
        align-items: flex-start!important;
    }
    .loginFormContainer ion-grid {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0!important;
    }
    .loginGrid .logo {
        align-self: flex-end;
    }
    .loginForm {
        width: 100%;
        max-width: none!important;
        padding: 0 20px 20px!important;
    }
    .loginForm .btnRow {
        padding-top: 1em!important;
        padding-bottom: 2em;
    }
    .loginForm .btnRow ion-col {
        padding-left: 0!important;
        margin-right: 0!important;
    }
    .loginForm .btnRow ion-button {
        max-width: 100%!important;
        margin: none!important;
    }
    .blueoceanLogo {
        max-width: 55px!important;
        bottom: 20px!important;
    }
}

.loginGrid,
.loginGridRow {
    height : 100%;
}
.loginGrid .leftContainer {
    height: 100%;
}
.loginGrid .homeCover {
    display: block;
    width: 100%;
    height: 100%;
    background-size: cover;
}

.loginGrid .homeCover.beam {
    background: url('/assets/img/beam-cover.png');
    background-size: cover;
}

.loginGrid .homeCover.gobe {
    background: url('/assets/img/GoBe_Cover.png');
    background-size: cover;
}

.loginGrid .rightContainer {
    display: flex;
    flex-direction: column;
}
.loginFormContainer {
    flex-grow: 1;
    align-items: center;
}
.loginGrid ion-button {
    display: flex;
    text-transform: none;
}
.loginGrid .logoItem {
    --border-color: white;
}
.loginGrid .logo {
    color: var(--ion-color-primary);
    font-weight: bold;
    font-size: 2.5em;
    text-align: center;

    display: block;
    width: 100%;
    min-height: 108px;
    margin-bottom: 0.8em;
}
.loginGrid .logo.beam {
    background: url('/assets/img/beam-logo.svg');
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
}

.loginGrid .logo.gobe {
    background: url('/assets/img/gobe-robots-logo.svg');
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
}
.loginGrid .welcomeLb {
    width: 100%;
    color: var(--ion-color-gray);
}
.loginForm {
    padding: 20px;
    max-width: 65%;
    display: block;
    margin: 0 auto;
}
.loginForm ion-row {
    padding-top: 1.15em;
}
.loginForm .loginList {
    width: 100%;
}
.loginForm .btnRow {
    padding-top: 3em;
}
.loginForm .btnRow ion-col {
    padding-left: 1em;
    margin-right: 1em;
}
.loginForm .btnRow ion-button {
    margin: 0 auto;
    text-transform: uppercase;
    --background-activated: var(--ion-color-primary);
    --background-activated-opacity: 0.85;
}
.forgotPswLink {
    color: var(--ion-color-primary);
}
.forgotPswLink:hover {
    color: var(--ion-color-primary-shade);
    cursor: pointer;
}
.errorContainer {
    color: var(--ion-color-danger);
}
.dividerContainer {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.dividerContainer div:nth-of-type(1) {
    flex-grow: 1;
}
.dividerContainer div:nth-of-type(3) {
    flex-grow: 1;
}
.divider {
    height: 2px;
    background-color: var(--ion-background-color-divider);
}
.logoContainer {
    width: 109px;
    height: 67px;
}
.blueoceanLogo {
    width: calc(100% - 30px);
    height: 38px;
    margin: 9px 15px 20px;
}
.loginBtn {
    font-size: 18px;
    max-width: 185px;
}

.noAccountAction {
    width: 100%;
    text-align: center;
    margin: 32px 0;
}