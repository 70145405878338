// @font-face {
// 	font-family: 'Industry';
// 	font-style: normal;
// 	src: url('./fonts/Industry-Medium.ttf'); 
// }
// @font-face {
// 	font-family: 'Industry-Demi';
// 	font-style: normal;
// 	src: url('./fonts/Industry-Demi.ttf'); 
// }
body {
	outline: none;
	font-family: Roboto, Roboto Slab, Industry, Industry-Demi;
}
