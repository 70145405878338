.filterList {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.filterLb {
    margin-left: 0.5rem;
    font-weight: bold;
    text-transform: uppercase;
}
.filterItem {
	--background-hover: var(--ion-main-background-color);
    --background-hover-opacity: 1;
    border-radius: 0.313rem;
}
.selected {
    --background: var(--ion-main-background-color);
}