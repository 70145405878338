.infoContainer {
	display: flex;
	width: 100%;
	position: relative;
	margin-right: 1.5em;
	margin-bottom: 1.5em;
}
.dataCard {
	background-color: var(--ion-color-primary-contrast);
	border-radius: 5px;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
	width: 100%;
	margin: 0;
}
.dataCard ion-card-title {
    font-family: 'Industry';
    font-size: 18px;
    font-weight: bold;
    line-height: 0.72;
    padding-top: 0.1em;
}
.dataCard ion-card-content {
	padding: 0;
}
.dataCardContent {
	--border-color: #f3f3f3;
	--inner-padding-end: 0;
	--padding-start: 0.5rem;
	--padding-end: 0.5rem;
}
.closeIcon {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0.5rem 0 0;
}
.closeIcon:hover {
	cursor: pointer;
}
.profileContainer {
	display: flex;
	align-items: center;
}
.userAvatar {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 5.063rem;
	height: 100%;
	margin: 0 0.5rem 2rem 0;
	padding-top: 1rem;
}
.profilePic {
	width: 5.063rem;
	height: 5.063rem;
}
.editPicFab {
	position: absolute;
	bottom: 0.5rem;
}
.editPicBtn {
	--background: rgba(255, 255, 255, 0.75);
	--color: var(--ion-text-color);
	--box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
	border-radius: 50%;
}
.editPicIcon {
	--color: var(--ion-text-color);
}
.noteContainer {
	display: flex;
	align-items: center;
}
.deleteFab,
.editFab {
	position: absolute;
	right: 0;
}
.deleteFab ion-fab-button {
	margin: 0;
	bottom: 0;
	--background: var(--ion-color-danger);
	--background-hover: var(--ion-color-danger-hover);
	--background-hover-opacity: 1;
	--color: var(--ion-color-primary-contrast);
	--box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
}
.editFab ion-fab-button {
	margin: 0;
	bottom: 0;
	--background: var(--ion-main-background-color);
	--color: var(--ion-color-gray-shade);
	--box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
}
.deleteOverlay {
	position: absolute;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	border-radius: 5px;
	--background: var(--ion-text-color);
	background-color: var(--ion-text-color);
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
	z-index: 1;
	opacity: 0.9;
}
.deleteOverlay ion-row:first-child {
	flex-grow: 1;
}
.confirmLb {
	width: 100%;
	color: var(--ion-color-primary-contrast);
	text-align: center;
	align-self: center;
	font-family: 'Industry';
	font-weight: 500;
	font-size: 18px !important;
}
.closeFabBtn {
	--color: var(--ion-color-primary-contrast);
	--background: var(--ion-color-gray-shade);
	padding: 0.25rem;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
}
.actionContainer {
	display: flex;
	margin: 0 2.25rem 1rem;
}
.cancelBtn {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-text-color);
	--background: transparent;
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--border-color: var(--ion-color-primary-contrast);
	--border-width: 0.08rem;
	--border-style: solid;
	flex: 1;
	margin: 0 0.5rem 0 0;
	font-size: 13px;
}
.deleteBtn {
	--background: var(--ion-color-danger);
	flex: 1;
	margin: 0 0 0 0.5rem;
	font-size: 13px;
}
.textWrap {
	overflow: hidden;
    text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 200px;
	line-height: normal;
}