.formGrid {
    width: 100%;
    padding: 0;
}
.formGrid ion-list {
    width: 100%;
    margin-bottom: 1.2rem;
}
.formGrid ion-item {
    opacity: 1;
    --border-color: var(--ion-main-background-color);
}
.formGrid ion-list-header {
    margin-top: 1.063rem;
    --background: var(--ion-main-background-color);
    font-family: 'Industry';
    text-transform: uppercase;
    border-radius: 5px;
}
.formGrid ion-input.editable {
    border-radius: 4px;
    border: 1px solid #ccc;
    max-width: 300px;
}
.formGrid ion-input.firstName {
    margin-right: 0.5rem;
}
.formGrid ion-label.userName {
    margin-left: 0.5rem;
}
.formLb {
    font-weight: bold;
    margin-right: 4rem;
    min-width: 170px!important;
}
.formLbItem {
    margin-right: 8px;
    background-color: #eeeeee;
    border-radius: 5px;
    height: 30px;
    padding: 7px 8px;
    flex: unset !important;
    width: fit-content !important;
}
.inputLb {
    margin-left: 0.5rem;
}
.subTitleLb {
    font-family: 'Industry';
    text-transform: capitalize;
    --color: var(--ion-color-gray)!important;
}
.chkLb {
    margin-left: 0.5rem;
}
.ddlLb {
    flex: 1!important;
}
.selectLb {
    margin-left: 0!important;
    font-weight: bold;
    margin-right: 4rem;
    min-width: 170px!important;
}
.groupSelect {
    max-width: 300px;
    flex: 1;
}
.hidden {
    display: none !important;
}