.listGrid {
	padding: 0;
	margin: 0;
	height: 100%;
	/* width: 100%; */
	flex-grow: 1;
}
.headerRow {
	display: flex;
	align-items: center;
	height: 2.813rem;
	color: var(--ion-color-primary-contrast);
	background-color: var(--ion-text-color);
	border-radius: 5px;
	font-family: 'Industry';
	font-weight: bold;
	padding-left: 1rem;
}
.centeredCol {
	text-align: center;
	display: flex;
	justify-content: center;
}
.dataRow {
	background-color: var(--ion-color-primary-contrast);
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
	border-radius: 5px;
	margin-top: 0.5rem;
	align-items: center;
	padding-left: 0.5rem;
}
.dataRow:hover {
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
	cursor: pointer;
}
.createdRow {
	background-color: #edf8ec;
}
.noData {
	margin-top: 1rem;
	padding-left: 0.5rem;
}
.noData:hover {
	cursor: default;
}
.moreCol {
	max-width: 3.75rem;
}
.moreIcon {
	color: var(--ion-color-gray);
	border-radius: 50%;
	padding: 0.375rem;
}
.moreIcon:hover {
	background-color: var(--ion-main-background-color);
	cursor: pointer;
}
.paginationContainer {
	display: flex;
	align-items: center;
}
.pagingInfo {
	flex-grow: 1;
	font-weight: bold;
}
.pagination {
	text-align: right;
	padding-left: 15px;
	padding-right: 15px;
}
.pagination li {
	display: inline-block;
}
.pagination li > a {
	font-size: 13px;
	color: var(--ion-text-color);
	border-radius: 50%;
	padding: 9px 13px;
	margin: 0 3px;
	cursor: pointer;
}
.pagination li:first-child a,
.pagination li:last-child a {
	padding: 9px;
}
.pagination li a:hover {
	background-color: var(--ion-lb-color-light);
}
.pagination .icon {
	top: 3px;
	position: relative;
}
.activePage {
	background-color: var(--ion-color-gray-shade) !important;
	color: var(--ion-color-primary-contrast) !important;
	border-color: var(--ion-text-color) !important;
}
.disabledPage a {
	color: #ccc !important;
	cursor: default !important;
}
.disabledPage a:hover {
	background-color: transparent !important;
}
.info {
	background-color: var(--ion-color-primary);
	color: var(--ion-color-primary-contrast);
}
.hide {
	display: none;
}
.popup {
	--box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.015);
	border-radius: 10px;
}
.elementContainer {
	height: inherit;
	display: inline-flex;
	align-items: center;
}
.closeIcon {
	margin-left: 10px;
	width: 25px;
	height: 25px;
}
.columnHeader {
	display: flex;
	align-items: center;
}
.sortContainer {
	display: flex;
	flex-direction: column;
}
.sortIcon {
	color: #78787a;
	height: 0.7rem;
	margin-left: 0.2rem;
}
.sortIcon:hover,
.activeCol:hover {
	cursor: pointer;
}
.activeSort {
	color: var(--ion-color-primary-contrast);
}
.checkbox {
	margin: 10px 15px;
}
