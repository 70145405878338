.wrapper {
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 0 10%;
}

.line {
	flex-grow: 1;
	height: 2px;
	margin: 0 15px;
	background-color: #b2b2b2;
}

.blueOceanLogo {
	margin-bottom: 14px;
}