#jsd-widget {
	position: absolute !important;
	min-height: 70px !important;
	min-width: 70px !important;
}

.tooltip .react-tooltip-lite {
	position: absolute;
	background-color: #ffffff !important;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15) !important;
	border-radius: 5px !important;
	color: black !important;
}
.tooltip .react-tooltip-lite-arrow {
	color: #ffffff !important;
}
.tooltip .react-tooltip-lite-arrow::after {
	content: '';
	position: absolute;
	width: 50px;
	height: 50px;
	color: #ffffff !important;
	transform: rotate(225deg);
	left: 12px;
	box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5);
}
