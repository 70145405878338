@media (max-width: 576px) {
    .contentGrid .topLogoContainer {
        background-color: var(--ion-color-primary);
        flex-grow: 1;
    }
    .formContainer {
        align-items: flex-start!important;
    }
    .formContainer ion-grid {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0!important;
    }
    .contentGrid .logo {
        align-self: flex-end;
    }
    .btnRow {
        padding-top: 1em!important;
        padding-bottom: 2em;
    }
    .btnRow ion-col {
        padding-left: 0!important;
        margin-right: 0!important;
    }
    .blueoceanLogo {
        max-width: 55px!important;
        bottom: 20px!important;
    }
} 
@media (min-width: 576px) and (max-width: 768px) {
    .contentGrid .topLogoContainer {
        background-color: var(--ion-color-primary);
        flex-grow: 1;
    }
    .formContainer {
        align-items: flex-start!important;
    }
    .formContainer ion-grid {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0!important;
    }
    .contentGrid .logo {
        align-self: flex-end;
    }
    .btnRow {
        padding-top: 1em!important;
        padding-bottom: 2em;
    }
    .btnRow ion-col {
        padding-left: 0!important;
        margin-right: 0!important;
    }
    .blueoceanLogo {
        max-width: 55px!important;
        bottom: 20px!important;
    }
}
.contentGrid,
.contentGridRow {
    height : 100%;
}
.contentGrid .leftContainer {
    height: 100%;
}
.homeCover {
    display: block;
    width: 100%;
    height: 100%;
    background-size: cover;
}

.homeCover.beam {
    background: url('/assets/img/beam-cover.png');
    background-size: cover;
}

.homeCover.gobe {
    background: url('/assets/img/GoBe_Cover.png');
    background-size: cover;
}

.contentGrid .rightContainer {
    display: flex;
    flex-direction: column;
}
.contentGrid ion-button {
    display: flex;
    text-transform: none;
}
.contentGrid .logo {
    margin-bottom: 0.5em;
    color: var(--ion-color-primary);
    font-weight: bold;
    font-size: 2.5em;
    text-align: center;
    display: block;
    width: 100%;
    min-height: 108px;
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 0.8em;
}
.contentGrid .logo.beam {
    background: url('/assets/img/beam-logo.svg');
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
}

.contentGrid .logo.gobe {
    background: url('/assets/img/gobe-robots-logo.svg');
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
}

.contentGrid .lbInstruction {
    display: block;
    max-width: 400px;
    color: var(--ion-color-gray);
    padding-bottom: 0.5em;
    margin-bottom: 1.5em;
    text-align: left!important;
    text-transform: capitalize;
}
.formContainer {
    flex-grow: 1;
    align-items: center;
}
.formContainer ion-list {
    min-width: 450px;
}
.btnRow {
    padding-top: 0.5em;
}
.btnRow ion-col {
    padding-left: 1em;
    margin-right: 1em;
}
.btnRow ion-button {
    max-width: 250px;
    margin: 0 auto;
    text-transform: uppercase;   
    --background-activated: var(--ion-color-primary);
    --background-activated-opacity: 0.85;
}
.dividerContainer {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.dividerContainer div:nth-of-type(1) {
    flex-grow: 1;
}
.dividerContainer div:nth-of-type(3) {
    flex-grow: 1;
}
.divider {
    height: 2px;
    background-color: var(--ion-background-color-divider);
}
.logoContainer {
    width: 109px;
    height: 67px;
}
.blueoceanLogo {
    width: calc(100% - 30px);
    height: 38px;
    margin: 9px 15px 20px;
}