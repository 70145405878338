@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,700');
@import url('font-industry.css');

:root,
:root[mode=ios],
:root[mode=md] {
  --ion-font-family: 'Roboto'!important;
  font-family: 'Roboto'!important;
}

:root {
	/** primary **/
	--ion-color-primary: #00c790;
	--ion-color-primary-rgb: 0, 0, 0;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #0D8B7B;
	--ion-color-primary-tint: #79E3D1;

	/** secondary **/
	--ion-color-secondary: #3d4f53;
	--ion-color-secondary-rgb: 49, 49, 49;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #364649;
	--ion-color-secondary-tint: #506164;

	/** gray **/
	--ion-color-gray: #a5a5a6;
	--ion-color-gray-rgb: 165,165,166;
	--ion-color-gray-contrast: #000000;
	--ion-color-gray-contrast-rgb: 0,0,0;
	--ion-color-gray-shade: #78787a;
	--ion-color-gray-tint: #d2d2d2;

	/** danger **/
	--ion-color-danger: #d92e2e;
	--ion-color-danger-rgb: 217,46,46;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #bf2828;
	--ion-color-danger-tint: #EC9696;

	/** background **/
	--ion-background-color: #ffffff;
	--ion-background-color-rgb: 255, 255, 255;

	/** text & font **/
	--ion-font-size: 15px;
	--ion-text-color: #2D2A26;
	--ion-text-color-rgb: 165, 165, 166;

	/** home page **/
	--ion-header-icon-color:var(--ion-color-gray);
	--ion-main-background-color: #eeeeee;

	/** toolbar & main menu **/
	--ion-color-toolbar: #2D2A26;
	--ion-height-toolbar: 70px;
	--ion-color-menu: #2D2A26;
	--ion-color-menu-icon: #ffffff;
	--ion-color-menu-item-hover: #ffffff;
	--ion-background-menu-item-active: var(--ion-color-primary);
	--ion-background-menu-item-hover: #78787a;

	/** custom variables **/
	--ion-btn-border-radius: 30px;
	--ion-lb-color-light: var(--ion-color-gray-tint);
	--ion-color-danger-hover: #aa0e11;
	--ion-more-lb-color: #5c5956;
	--ion-text-color-light: var(--ion-color-gray);

	/** footer divider **/
	--ion-background-color-divider: rgba(0, 0, 0, 0.25);
	--ion-border-top-color-divider: 1px solid #b9b7b4;
	--ion-border-bottom-color-divider: 1px solid #9a9d9c;

	--robot-logo: url('/assets/img/beam-logo.svg');
	--robot-logo-white: url('/assets/img/beam-logo-white.svg');
	--robot-logo-black: url('/assets/img/beam-logo-black.svg');

	--login-page-image: url('/assets/img/beam-cover.png');

	/** Variables for Form fields **/
	--form-field-hover-color: #cfcfcf;
	--form-field-focus-color: #aeafb2;
	--form-field-active-color: #a6a7a9;
	--form-field-error-color: #d92e2e;
}

ion-searchbar {
	--box-shadow: none !important;
}
ion-input {
	font-size: var(--ion-font-size)!important;;
}
ion-label {
	--color: var(--ion-text-color);
	font-size: var(--ion-font-size)!important;
}
ion-button.round  {
	border: 1px solid var(--ion-color-primary);
    border-radius: var(--ion-btn-border-radius);
	--border-radius: var(--ion-btn-border-radius)!important; 
	--color-hover: var(--ion-color-primary)!important;
    --background-hover: var(--ion-color-primary-contrast)!important;
	--background-hover-opacity: 1;
	--box-shadow: none!important;
}
ion-button.transparent {
	border: 1px solid var(--ion-text-color);
    border-radius: var(--ion-btn-border-radius);
	--color: var(--ion-text-color);
	--color-hover: var(--ion-color-primary-contrast);
	--border-color: var(--ion-text-color);
	--border-width: 1px;
	--background: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-text-color);
	--background-hover-opacity: 1;
	--box-shadow: none!important;
}
ion-progress-bar {
	height: 10px;
	border-radius: 10px;
	background-color: #eeeeee;
}

/** Add user modal in Users page **/
.addUserModal,
.editUserModal {
	--max-width: 400px;
	--max-height: 500px;
}

/** Add modal in UserGroups page **/
.addUserGroupModal,
.editUserGroupModal,
.deleteUserGroupModal {
	--max-width: 400px;
	--max-height: 600px;
}

/** Add user modal in accountmanagement page **/
.addPictureModal {
	--max-width: 400px;
	--max-height: 300px;
}

/** Add device modal in Robots page **/
.addDeviceModal {
	/* --max-height: 700px; */
	--max-height: 450px;
	--max-width: 400px;
}
.editDeviceModal {
	--max-height: 800px;
}

.inviteUserModal {
	--max-height: 813px;
	--max-width: 613px;
	--border-radius: 10px;
	--box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
	--background-color: #ffffff;
}
/* tooltip styles */
.react-tooltip-lite {
	background: var(--ion-text-color);
	color: white;
	border-radius: 2px;
	font-size: 13px;
}
